<template>
    <b-modal id="modal-add-service-invoice-VAT" v-model="isOpen" size="lg" centered hide-header :no-close-on-backdrop="true"> 
        <b-card-header class="banned-background-color">
            <div class="d-flex justify-content-between align-items-center block-header">
                <h4 class="mb-0">
                    {{ $t("golf_invoice_vat_add_service") }}
                </h4>
                <ez-icon icon="ezGolf-icon-x" class="cursor-pointer" size="24" @click="hideModal" />
            </div>
        </b-card-header>
        <b-card-body>
            <div class="mb-1">
             
                <!-- <ez-icon
                  size="18"
                  icon="ezGolf-icon-user"
                /> -->
              </div>  
                  <!-- <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-input
                          v-model="detailTaxInvoice.RoomNumber"
                          :placeholder="$t('golf_tax_invoice_room_number')"
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="detailTaxInvoice.CheckIn"
                            :placeholder="$t('golf_tax_invoice_checkin')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="detailTaxInvoice.Checkout"
                            :placeholder="$t('golf_tax_invoice_checkout')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row> -->
                  <!-- <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                            <v-select
                              v-model="detailTaxInvoice.CashierCode"
                              :options="listCashiers"
                              label="label"
                              :reduce="label => label.id"
                              :clearable="false"
                              :multiple="false"
                              :placeholder="$t('golf_sidebar_checkout_cashier')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row> -->
                  <b-row class="mb-2">
                    <b-col>
                      <h4 class="">
                    {{ $t("golf_invoice_vat_list_service") }}
                </h4>
                    </b-col>
                    <!-- <div class="ml-auto mr-1" >
                      
                        <b-button class="mr-1" variant="primary" :disabled="isLoading" @click="updateInvoice()">
                {{ $t('golf_common_add') }}
                <b-spinner v-if="isLoading" small label="Loading..." />
            </b-button>
            <b-button variant="secondary" @click="hideModal()">
              {{ $t('golf_common_cancel') }}
            </b-button>
                     
          </div> -->
                  </b-row>
                  <b-row v-if="checkModuleEInvocie[0]=='2'" >

                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <date-range-picker
                            ref="picker"
                            v-model="search.FromDate"
                            :single-date-picker="true"
                            opens="right"
                            :ranges="false"
                            :time-picker="false"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :placeholder="$t('golf_common_scan_bdc')"
                            :locale-data="{
                              format: 'DD/MM/YYYY',
                              daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                              monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              firstDay: 0
                            }"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <date-range-picker
                            ref="picker"
                            v-model="search.ToDate"
                            :single-date-picker="true"
                            opens="right"
                            :ranges="false"
                            :time-picker="false"
                            :time-picker-increment="1"
                            :placeholder="$t('golf_common_scan_bagtag')"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                              format: 'DD/MM/YYYY',
                              daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                              monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              firstDay: 0
                            }"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    
                      <b-col> 
              <b-form-group>
                <b-form-input
                  v-model="search.KeyWord"
                  :placeholder="$t('golf_invoice_vat_add_service_search_keyword')"
                  
                />
              </b-form-group>
            </b-col>
            <b-col>
                      <div class="form-group">
                      

                        <treeselect :options="listCashiers" 
                        :multiple="true"
                        :placeholder="$t('golf_sidebar_checkout_title')"
                        :reduce="label => label.id"
                         v-model="search.CashierCode"
                        >
                       
                        </treeselect>
                      </div>
                    </b-col>

              <div class="ml-auto mr-1">

                <b-button  variant="primary" :disabled="isLoading" @click="getListPaymentService_RPMI03()">
                {{ $t('golf_common_search') }}
                <b-spinner v-if="isLoading" small label="Loading..." />
            </b-button>

          </div>
               
                        
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="d-bdc-search">
                <b-form-group>
                  <v-select
                    ref="listBDC"
                    v-model="search.BDC"
                    taggable
                    multiple
                    class="custom-select-bdc hide-dropdown"
                    :placeholder="$t('golf_common_scan_bdc')"
                    @input="searchBy('BDC')"
                  >
                  </v-select>
                </b-form-group>
              </div>
                    </b-col>
                    <b-col>
                      <div class="d-bagtag-search">
                <b-form-group>
                  <v-select
                    ref="listBagtag"
                    v-model="search.BagTag"
                    taggable
                    multiple
                    class="custom-select-bdc hide-dropdown"
                    :placeholder="$t('golf_common_scan_bagtag')"
                    @input="searchBy('BAGTAG')"
                  >
                  <template v-slot:no-options="{ search, searching }">
                      <template v-if="searching">
                        No results found for <em>{{ search }}</em>.
                      </template>
                      <em
                        v-else
                        style="opacity: 0.5"
                      >{{ $t('golf_common_scan_bagtag') }}</em>
                    </template>
                  </v-select>
                </b-form-group>
              </div>
                    </b-col>
                  </b-row>
                  <div class="p-2">
        <b-row >
          <b-col md="3">
            <div class="d-select-perPage">
              <b-form-group
                :label="$t('golf_starter_result_on_page')"
                label-for="h-first-name"
                label-cols-md="7"
              >
                <v-select
                  v-model="perPage"
                  :options="optionPerPage"
                  :clearable="false"
                />
              </b-form-group>
            </div>
          </b-col>
        </b-row>
      </div>
                  <div
          class="d-table-starter"
        >
          <DxDataGrid
            :ref= "dataGridRef"
            class="table-starter"
            :show-borders="false"
            :data-source="listPaymentService"
            :show-column-lines="true"
            :show-row-lines="true"
            :allow-column-reordering="true"
            :allow-column-resizing="false"
            column-resizing-mode="widget"
            :column-auto-width="true"
          >
            <DxColumn
              v-for="(item, i) in configTable"
              v-if="(item.field != 'action') ? item.isShow : true"
              :key="i.field"
              :caption="item.caption"
              :data-field="item.field"
              :data-type="item.type"
              :alignment="item.alignment"
              :cell-template="(item.cellTemplate) ? item.cellTemplate : false"
              :allow-sorting="false"
              :width="item.width"
              :cssClass="(item.field == 'Note') ? 'table-starter-wrapped-column' : ''"
            >
              <DxColumn
                v-for="(band, j) in item.bands"
                v-if="band.isShow && item.bands.length > 0"
                :key="band.field"
                :caption="band.caption"
                :data-field="band.field"
                :data-type="band.type"
                :group-cell-template="customText"
                :cell-template="(band.cellTemplate) ? band.cellTemplate : false"
                :alignment="band.alignment"
                :width="item.width"
                :allow-sorting="false"
                :cssClass="(item.field == 'Note') ? 'table-starter-wrapped-column' : ''"
              />
            </DxColumn>
            <DxSelection
                  :select-all-mode="allMode"
                  :show-check-boxes-mode="checkBoxesMode"
                  mode="multiple"
                />
            <template #total-price="{ data }">
              <div v-if="data.data.Amount">
                {{ formatPrice(data.data.Amount) }}
              </div>
            </template>

            <!-- <template #create-date="{ data }">
              <div v-if="data.data.CreateDate">
                {{ convertUTC(data.data.CreateDate,'DD/MM/YYYY HH:mm') }}
              </div>
            </template> -->

            <!-- Bật cố định column -->
            <DxColumnFixing :enabled="true" />

            <!-- Cho phép group -->
            <DxGroupPanel :visible="false" />

            <!-- Phân trang -->
            <DxPaging :page-size="perPage" />
            <DxPager
              :visible="true"
              display-mode="full"
              :show-info="true"
            />
          </DxDataGrid>
        </div>
        </b-card-body>
        <template #modal-footer>
            <b-button variant="primary" :disabled="isLoading" @click="getServiceEInvoice()">
                {{ $t('golf_common_add') }}
                <b-spinner v-if="isLoading" small label="Loading..." />
            </b-button>
            <b-button variant="secondary" @click="hideModal()">
              {{ $t('golf_common_cancel') }}
            </b-button>
        </template>
    </b-modal>
</template>
<script>
import { invoice } from '@/api/invoice'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import { data } from 'jquery';
import Treeselect from '@riophae/vue-treeselect'
// import the styles
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
const dataGridRef = 'dataGrid'
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        Treeselect,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
    },
    props: [
      'listCashiers',
    ],
    data() {
        return {
            dataGridRef,
            dataGridInstance: null,
            isOpen: false,
            selectedRowsData: [],
            listPaymentService:[],
            search:{
              BDC: [],
              Bagtag: [],
              CashierCode:[],
              FromDate: this.getUTC('start', 'day', -3),
              ToDate: this.getUTC('start', 'day'),
              KeyWord:'',
            },
            ignoreKeyWord: false, // Cờ để kiểm soát KeyWord
            // BDC: [],
            // Bagtag: [],
            // CashierCode:'',
            // FromDate: this.getUTC('start', 'day', -3),
            // ToDate: this.getUTC('start', 'day'),
            // KeyWord:'',
            detailTaxInvoice: {
                MemberName: null,
                ClientId: null,
                CompanyName: null,
                CompanyAddress: null,
                Email: null,
                TaxCode: null,
                PaymentTypeCode: null,
                PaymentMethodId: null,
                TransactionCode: null,
                RoomNumber: null,
                CheckIn: null,
                Checkout: null,
                CashierCode: null,
                PaymentBookingDetailService: [],
                SerialNo: null,
                FormNo: null,
                RefNo: null
            },
            isSelectListMember: true,
            paymentTypeName: null,
            canShowPaymentType: false,
            checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
            isLoading: false,
            ListFormNo:[],
            ListSerialNo:[],
            ListSerialNoAll:[],
            SerialNoDefault: null,
            FormNoDefault: null,
            currentPage: 1,
            allMode: 'allPages',
            checkBoxesMode: 'onClick', // 'always' :
            perPage: 10,
           // dataGridRef: 'tablePackage',
            optionPerPage: [5, 10, 50, 100],
            configTable: [
            {
              caption: 'BDC',
              field: 'BDC',
              isShow: true,
              alignment: 'left',
              width: '12%',
            },
            {
              caption: 'Bagtag  ',
              field: 'Bagtag',
              isShow: true,
              alignment: 'left',
              width: '8%',
            },
            {
              caption: this.$t('golf_common_full_name'),
              field: 'FullName',
              isShow: true,
              alignment: 'left',
              width: '10%',
            },
            {
              caption: this.$t('golf_tax_invoice_payment_code'),
              field: 'PaymentCode',
              isShow: true,
              alignment: 'left',
              width: '10%',
            },
            {
              caption: this.$t('golf_tax_invoice_cashier_name'),
              field: 'CashierName',
              isShow: true,
              //cellTemplate: 'create-date',
              alignment: 'left',
              width: '10%',
            },
            {
              caption: this.$t('golf_tax_invoice_service_name'),
              field: 'ServiceName',
              isShow: true,
              //cellTemplate: 'create-date',
              alignment: 'left',
              width: '16%',
            },
            {
              caption:  this.$t('golf_tax_invoice_service_name_einvoice'),
              field: 'ServiceNameEInvoice',
              isShow: true,
              //cellTemplate: 'create-date',
              alignment: 'left',
              width: '16%',
            },
            {
              caption:  this.$t('golf_common_total'),
              field: 'Amount',
              isShow: true,
              cellTemplate: 'total-price',
              alignment: 'right',
              width: '14%',
             },
            // {
            //   caption: 'Đơn giá',
            //   field: 'CreateDate',
            //   isShow: true,
            //   cellTemplate: 'create-date',
            //   alignment: 'center',
            //   width: '13%',
            // }
      ],

        }
    },
    created() {
      this.call_api_RICF01()
      //this.getListPaymentService_RPMI03()
    },
    computed: {
    // dataGrid() {
    //   return this.$refs[dataGridRef].instance
    // },
  },
    watch: {
        isOpen(value) {
            if(value) {
                //this.detailInvocie_RIVAT02(this.data.Id)
                this.getListPaymentService_RPMI03()
                //this.getSelectedData()
            } else {
                //this.clearData()
            }
        },
        search: {
      handler() {
        // Loại trừ thay đổi của KeyWord
        
        this.getListPaymentService_RPMI03();
      },
      deep: true, // Theo dõi toàn bộ object
    },
    //     listBDC() {
    //     setTimeout(() => {
    //     this.$refs.listBDC.$refs.search.focus()
    //       }, 10)
    //     },
    //     listBagtag() {
    //       setTimeout(() => {
    //       this.$refs.listBagtag.$refs.search.focus()
    //       }, 10)
    // },
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-add-service-invoice-VAT')
            this.search.BDC = []
            this.search.Bagtag = []
            this.search.CashierCode=[]
            this.search.FromDate= this.getUTC('start', 'day', -3)
            this.search.ToDate = this.getUTC('start', 'day')
            this.search.KeyWord=''
            
        },
        hasNonKeyWordChanges(newVal, oldVal) {
      const { KeyWord: newKeyWord, ...newRest } = newVal;
      const { KeyWord: oldKeyWord, ...oldRest } = oldVal;
      console.log(newVal)
      console.log(oldVal)
      // So sánh các phần còn lại (không bao gồm KeyWord)
      return JSON.stringify(newRest) !== JSON.stringify(oldRest);
    },
        async call_api_RICF01() {
      // Lấy danh sách config
      const response= await invoice.api_RICF01()
          if (response.Status === '200') {
            this.ListFormNo =this.desired_output(response.Data.InvoiceConfig).map(e=>({
              FormNo:e
            }));
            this.ListSerialNoAll =response.Data.InvoiceConfig
            const checkDefaultInvoice = response.Data.InvoiceConfig.filter(x => x.DefaultInvoiceSymbol === true)
            if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
              this.SerialNoDefault = checkDefaultInvoice[0].SerialNo
              this.FormNoDefault = checkDefaultInvoice[0].FormNo
              this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.FormNoDefault)
            } }
    },
    desired_output(arr){
      let unique_values = arr
          .map((item) => item.FormNo)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
      return unique_values;
  },
  async getListPaymentService_RPMI03() {
      this.isLoading = true
      await invoice.api_RPMI03(this.search)
        .then(res => {
          if (res) {
            this.listPaymentService = res.Data.PaymentService
          }
        })
      this.isLoading = false
    },
    getSelectedData() {
     
      this.selectedRowsData = this.$refs[dataGridRef].instance.getSelectedRowsData()
    },
    getServiceEInvoice() {
      this.getSelectedData()
      
      this.$emit('event', { type: 'get_payment_service_adjust_einvoice', data: this.selectedRowsData })
      
      this.hideModal()


    },
     searchBy(value) {
      if (value == 'BDC') {
        //this.$refs.listBDC.$refs.search.focus();
        setTimeout(() => {
            this.$refs.listBDC.$refs.search.focus()
          },100)
      }
      if (value == 'BAGTAG') {
        setTimeout(() => {
            this.$refs.listBagtag.$refs.search.focus()
          },100)
      }
    },
    
}
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/dx-grid.scss';
#modal-add-service-invoice-VAT {
    .paymentType {
            padding: 10px 20px;
            &-header {
                display: flex;
                font-weight: bold;
                &-item {
                    // width: 33%;
                    padding: 5px 0;
                }
            }
            &-select {
                // display: flex;
                // font-weight: normal;
                &-item {
                    // width: 33%;
                    padding: 5px 0;
                    cursor: pointer;
                }
                &-method:hover {
                    background-color: #e2e9f3;
                    color: #114a9f;
                    cursor: pointer;
                }
            }
    }
    .modal-lg {
    max-width: 65% !important;
    }
    
    .dx-checkbox-container {
        justify-content: center;
}
}
</style>