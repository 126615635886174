<template>
    <b-modal id="modal-replace-invoice-VAT" v-model="isOpen" size="lg" centered hide-header :no-close-on-backdrop="true">
        <b-card-header class="banned-background-color">
            <div class="d-flex justify-content-between align-items-center block-header">     
                <h4 class="mb-0" >
                    {{ $t("golf_invoice_vat_replace") }}  {{ replaceTaxInvoice.InvoiceNo }} 
                </h4>
                <ez-icon icon="ezGolf-icon-x" class="cursor-pointer" size="24" @click="hideModal" /> 
            </div>
        </b-card-header>
        <b-card-body>
          <b-spinner v-if="isLoading" label="Loading..." />
            <div class="mb-1">
              <h5 class="mb-2">
                    {{ $t("golf_invoice_vat_info_guest") }}
                </h5>
                <div>
                  <span class="mb-0"> 
                    {{ $t("golf_sidebar_checkout_input_payment_person") }}
                  </span>
                <ez-icon
                  size="18"
                  icon="ezGolf-icon-user"
                />
                </div>
                <!-- <ez-icon
                  size="18"
                  icon="ezGolf-icon-user" 
                /> -->
              </div>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <!-- {{ replaceTaxInvoice.MemberId }} -->
                            <b-form-input
                              v-model="replaceTaxInvoice.MemberName"
                              :placeholder="$t('golf_sidebar_checkout_input_payment_person')"
                            />

                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col class="m-auto">
                      <b-form-group>
                              <b-form-checkbox v-model="replaceTaxInvoice.BuyerNotGetInvoice" @change="changeCheckVisitorNoInvoice(replaceTaxInvoice.BuyerNotGetInvoice)">
                            {{ $t('golf_sidebar_merge_visitor_no_invoice') }}
                          </b-form-checkbox>
                        </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                            <v-select
                              v-model="replaceTaxInvoice.ClientId"
                              :options="listClient"
                              label="ClientName"
                              :clearable="false"
                              :reduce="ClientName => ClientName.Id"
                              :placeholder="$t('golf_common_client')"
                              :disabled="replaceTaxInvoice.BuyerNotGetInvoice"
                              @input="onChangeClient()"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <div class="form-group">
                          <b-form-input 
                            :disabled="replaceTaxInvoice.BuyerNotGetInvoice"
                            v-model="replaceTaxInvoice.CompanyName"
                            :placeholder="$t('golf_tax_invoice_company_name')"
                          />
                      </div>
                    </b-col>
                    <b-col md="6">
                      <div class="form-group ">
                        <b-form-group>
                            <b-form-input
                              :disabled="replaceTaxInvoice.BuyerNotGetInvoice"
                              v-model="replaceTaxInvoice.Email"
                              :placeholder="$t('golf_tax_invoice_company_email')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col md="6">
                      <div class="form-group">
                        <b-form-group>
                            <b-form-input
                              :disabled="replaceTaxInvoice.BuyerNotGetInvoice"
                              v-model="replaceTaxInvoice.CompanyAddress"
                              :placeholder="$t('golf_tax_invoice_company_address')"
                            />
                        </b-form-group>
                      </div>
                    </b-col> 
                    <b-col md="6">
                      <div class="form-group">
                        <b-form-group>
                            <b-form-input
                              :disabled="replaceTaxInvoice.BuyerNotGetInvoice"
                              v-model="replaceTaxInvoice.TaxCode"
                              :placeholder="$t('golf_tax_invoice_tax_code')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col md="12">
                      <div class="form-group">
                        <b-row>
                          <b-col cols="12">
                            <b-form-group>
                                <v-select
                                  v-model="replaceTaxInvoice.PaymentMethod"
                                  :options="listPaymentMethodEInvocie"
                                  label="title"
                                  :reduce="option => option.value"
                                  :clearable="false"
                                  :multiple="false"
                                  :placeholder="$t('golf_payment_payment_type')"
                                /> 
                            </b-form-group>
                          </b-col>
                          <!-- <b-col cols="12">
                            <div>
                              <div
                                class="form-group"
                                @mouseleave="handlePaymentType('hide')"
                              >
                                <div
                                  class="form-control input-payment-method"
                                  @click="handlePaymentType('show')"
                                >
                                  <span
                                    v-if="paymentTypeName"
                                    class="text-truncate"
                                  >{{ paymentTypeName }}</span>
                                  <span v-else>{{ $t('golf_payment_payment_method') }}</span>
                                </div>
                                <div
                                  v-if="canShowPaymentType"
                                  class="dropdown-payment-method toggler d-flex flex-wrap"
                                >
                                  <div
                                    v-for="(type, k) in listPaymentType.find(x => x.Code === replaceTaxInvoice.PaymentTypeCode).PaymentMethod"
                                    :key="k"
                                    class="paymentType"
                                  >
                                    <div class="paymentType-header">
                                      <div
                                        class="paymentType-header-item text-nowrap"
                                      >
                                        {{ type.Name }}
                                      </div>
                                    </div>
                                    <div class="paymentType-select">
                                      <div
                                        class="paymentType-select-item w-100"
                                      >
                                        <div
                                          v-for="(trans, k) in type.TransactionCode"
                                          :key="k"
                                          class="paymentType-select-method text-nowrap"
                                          @click="handleChoicePayment(type, trans)"
                                        >
                                          {{ trans.Description }}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-col> -->
                        </b-row>
                      </div>
                      
                    </b-col>
                  </b-row>
                  <!-- <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-input
                          v-model="replaceTaxInvoice.RoomNumber"
                          :placeholder="$t('golf_tax_invoice_room_number')"
                        />
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="replaceTaxInvoice.CheckIn"
                            :placeholder="$t('golf_tax_invoice_checkin')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            v-model="replaceTaxInvoice.Checkout"
                            :placeholder="$t('golf_tax_invoice_checkout')"
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row> -->
                  <!-- <b-row>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                            <v-select
                              v-model="replaceTaxInvoice.CashierCode"
                              :options="listCashiers"
                              label="label"
                              :reduce="label => label.id"
                              :clearable="false"
                              :multiple="false"
                              :placeholder="$t('golf_sidebar_checkout_cashier')"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                  </b-row> -->
                  <b-row>
                    <b-col>
                      <h5 class="mb-2">
                    {{ $t("golf_invoice_vat_info_invoice") }}
                </h5>
                    </b-col>
                  </b-row>
                  <b-row v-if="checkModuleEInvocie[0]=='2'" > 
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <v-select
                              v-model="replaceTaxInvoice.FormNo"
                              :options="ListFormNo"
                              label="FormNo"
                              :reduce="label => label.FormNo"
                              :clearable="false"
                              :multiple="false"
                              @input="onChangeConfigEivoice()"
                              :placeholder="$t('golf_tax_invoice_formno')"
                              rows="2"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <v-select
                              v-model="replaceTaxInvoice.SerialNo"
                              :options="ListSerialNo"
                              label="SerialNo"
                              :reduce="label => label.SerialNo"
                              :clearable="false"
                              :multiple="false"
                              :placeholder="$t('golf_tax_invoice_serialno')" 
                              rows="2"
                            />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <b-form-input
                            disabled
                            v-model="replaceTaxInvoice.RefNo"
                            :placeholder="$t('golf_tax_invoice_refno')" 
                          />
                        </b-form-group>
                      </div>
                    </b-col>
                    <b-col>
                      <div class="form-group">
                        <b-form-group>
                          <date-range-picker
                            ref="picker"
                            v-model="AdjustInvoiceDate"
                            :single-date-picker="true"
                            opens="right"
                            :ranges="false"
                            :time-picker="false"
                            :time-picker-increment="1"
                            :show-dropdowns="true"
                            :auto-apply="true"
                            :locale-data="{
                              format: 'DD/MM/YYYY',
                              daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                              monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                              firstDay: 0
                            }"
                          />
                        </b-form-group> 
                      </div>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <h5 class="mb-2">
                    {{ $t("golf_invoice_vat_invoice_service") }}
                </h5>
                    </b-col>
                    <div  class="ml-auto mr-1" >
                      <b-button variant="primary" :disabled="isLoading" @click="viewAddServiceInvoiceVAT()">
                      {{ $t('golf_invoice_vat_add_service') }}
                      <b-spinner v-if="isLoading" small label="Loading..." />
                    </b-button>
                    
                    </div>
                  </b-row>
                  <div
          class="d-table-invoice-vat"
        >
          <DxDataGrid
            ref="tablePackage"
            class="table-invoice-vat"
            :show-borders="false"
            :data-source="InvoicePaymentBDSV"
            :show-column-lines="true"
            :show-row-lines="true"
            column-resizing-mode="widget"
          >
            <DxColumn
              v-for="(item, i) in configTable"
              v-if="(item.field != 'action') ? item.isShow : true" 
              :key="i.field"
              :caption="item.caption"
              :data-field="item.field"
              :data-type="item.type"
              :alignment="item.alignment"
              :cell-template="(item.cellTemplate) ? item.cellTemplate : false" 
              :width="item.width"
              :allow-sorting="false"
              :cssClass="'text-ellipsis'"
            >
              <DxColumn
                v-for="(band, j) in item.bands"
                v-if="band.isShow && item.bands.length > 0"
                :key="band.field"
                :caption="band.caption"
                :data-field="band.field"
                :data-type="band.type"
                :group-cell-template="customText"
                :cell-template="(band.cellTemplate) ? band.cellTemplate : false" 
                :alignment="band.alignment"
                
                :width="item.width"
                :cssClass="'text-ellipsis'"
              />
            </DxColumn>
            <template #total-price="{ data }">
              <div>
                {{ formatPrice(data.data.TotalAmount) }}
              </div>
            </template>
            <template #unit-price="{ data }">
              <div>
                {{ formatPrice(data.data.UnitPrice) }}
              </div>
            </template>
            <template #svc-amount="{ data }">
              <div>
                {{ formatPrice(data.data.ServiceCharge) }}
              </div>
            </template>
            <template #stax-amount="{ data }">
              <div >
                {{ formatPrice(data.data.SpecialTaxAmount) }} 
              </div>
            </template>
            <template #vat-amount="{ data }">
              <div>
                {{ formatPrice(data.data.TaxAmount) }}
              </div>
            </template>


            <template #action="{ data }">
              <ez-icon
          icon="ezGolf-icon-x"
          size="24"
          class="cursor-pointer ml-1"
          color="#EA5455"
          @click="DeleteServiceInvoiceVat(data.data)" 
        />
            </template>
            <!-- Bật cố định column -->
            <!-- <DxColumnFixing :enabled="true" /> -->

            <!-- Cho phép group -->
            <!-- <DxGroupPanel :visible="false" /> -->

            <!-- Phân trang -->
            <DxPaging :page-size="perPage" />
            <DxPager
              :visible="true"
              display-mode="full"
              :show-info="true"
            /> 
          </DxDataGrid>
        </div>
        </b-card-body>
        <template #modal-footer>
            <b-button variant="primary" :disabled="isLoading"  @click="$refs.confirmReplaceInvoiceVAT.open({
                          InvoiceId : replaceTaxInvoice.Id,
                          PaymentBookingDetailService:InvoicePaymentBDSV, 
                          dataConfirm: {
                            title: $t('golf_invoice_vat_confirm_invoice_replace_title'), //	Confirm invoice adjustment
                            content: $t('golf_invoice_vat_confirm_invoice_replace_content'),
                          }
                        })">
                {{ $t('golf_common_replace') }}
                <b-spinner v-if="isLoading" small label="Loading..." />
            </b-button>
            <!-- <b-button v-if="typeAdjustInvoice === 'Increase'" variant="primary" :disabled="isLoading" @click="updateInvoice()">
                {{ $t('golf_common_update') }}
                <b-spinner v-if="isLoading" small label="Loading..." />
            </b-button> -->
            <b-button variant="secondary" @click="hideModal()">
              {{ $t('golf_common_back') }}
            </b-button>
        </template>
        <AddServiceInvoiceVAT
      @event="handleEvent"
      :listCashiers="listCashiers"
      />
      <confirm-dialog
      ref="confirmReplaceInvoiceVAT"
      @confirmDialog="ReplaceInvoice_IIVAT04"
    />
    </b-modal>
</template>
<script>
import { invoice } from '@/api/invoice'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import AddServiceInvoiceVAT from './AddServiceInvoiceVAT.vue'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue' 

import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser, 
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'
import { A } from '@/api/constant';
export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        AddServiceInvoiceVAT,
        'confirm-dialog': ConfirmDialog,
        DxDataGrid,
        DxColumn,
        DxEditing,
        DxFilterRow,
        DxHeaderFilter,
        DxGroupPanel,
        DxGrouping,
        DxScrolling,
        DxPaging,
        DxPager,
        DxSummary,
        DxLookup,
        DxTotalItem,
        DxGroupItem,
        DxMasterDetail,
        DxStringLengthRule,
        DxRequiredRule,
        DxRangeRule,
        DxValueFormat,
        DxColumnChooser,
        DxColumnFixing,
        DxSelection,
        DxExport,
    },
    props: [
        'listMember',
        'listClient',
        'listPaymentTypeOpstion',
        'listPaymentType',
        'listCashiers',
        'data',
        'fullNameDefaultVisitorNoInvoice',
        'listPaymentMethodEInvocie', 
    ],
    data() {
        return {
            isOpen: false,
            InvoicePaymentBDSV:[],
            PaymentBookingDetailServiceReq:[],
            AdjustInvoiceDate: this.getUTC('start', 'day'),
            replaceTaxInvoice: {
                MemberName: null,
                InvoiceDate:this.getUTC('start', 'day'),
                ClientId: null,
                CompanyName: null,
                CompanyAddress: null,
                Email: null,
                TaxCode: null,
                PaymentTypeCode: null,
                PaymentMethodId: null,
                PaymentMethod: null,
                TransactionCode: null,
                RoomNumber: null,
                CheckIn: null,
                Checkout: null, 
                CashierCode: null,
                PaymentBookingDetailService: [],
                SerialNo: null,
                FormNo: null,
                RefNo: null
            },
            isSelectListMember: true,
            paymentTypeName: null,
            canShowPaymentType: false,
            checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
            isLoading: false,
            ListFormNo:[],
            ListSerialNo:[],
            ListSerialNoAll:[],
            SerialNoDefault: null,
            FormNoDefault: null, 
            currentPage: 1,
            perPage: 20,
            dataGridRef: 'tablePackage',
            optionPerPage: [10, 20, 50],
            configTable: [
            {
              caption: this.$t('golf_invoice_vat_service_name'),
              field: 'ServiceName',
              isShow: true,
              alignment: 'left',
              width: '15%',
            },
            {
              caption: this.$t('golf_invoice_vat_quantity'),
              field: 'Quantity',
              isShow: true, 
              alignment: 'center',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_unit'),
              field: 'UnitName',
              isShow: true,
              alignment: 'center',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_unit_price'), 
              field: 'UnitPrice',
              isShow: true,
              cellTemplate: 'unit-price',
              alignment: 'right',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_svc'),
              field: 'ServiceChargeValue',
              isShow: true,
              alignment: 'right',
              width: '8%', 
            },
            {
              caption: this.$t('golf_invoice_vat_svc_amount'),
              field: 'ServiceCharge',
              isShow: true,
              cellTemplate: 'svc-amount',
              alignment: 'right',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_stax'), 
              field: 'SpecialTaxValue',
              isShow: true,
              //cellTemplate: 'total-price',
              alignment: 'right',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_stax_amount'),
              field: 'SpecialTaxAmount', 
              isShow: true,
              alignment: 'right',
              cellTemplate: 'stax-amount',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_vat'),
              field: 'VatValue',
              isShow: true,
              //cellTemplate: 'total-price',
              alignment: 'right',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_vat_amount'),
              field: 'TaxAmount',
              isShow: true,
              cellTemplate: 'vat-amount',
              alignment: 'right',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_sum'),
              field: 'TotalAmount',
              isShow: true,
              cellTemplate: 'total-price', 
              alignment: 'right',
              width: '8%',
            },
            {
              caption: this.$t('golf_invoice_vat_action'),
              field: 'action',
              isShow: true,
              cellTemplate: 'action',
              alignment: 'center',
              width: '5%',
            },
      ],

        }
    },
    created() {
      this.call_api_RICF01()
    },
    watch: {
        isOpen(value) {
            if(value) {
                if(this.data != null || this.data != undefined)
                {
                  this.detailInvocie_RIVAT02(this.data.Id) 
                }
                //this.hideModal1()
                
            } else {
                this.clearData()
            }
        }
    },
    methods: {
        hideModal() {
            this.$bvModal.hide('modal-replace-invoice-VAT')
        },
        onChangeClient() {
            this.replaceTaxInvoice.CompanyName = this.listClient.find(x => x.Id == this.replaceTaxInvoice.ClientId).InvoiceName ? this.listClient.find(x => x.Id == this.replaceTaxInvoice.ClientId).InvoiceName : ''
            this.replaceTaxInvoice.CompanyAddress = this.listClient.find(x => x.Id == this.replaceTaxInvoice.ClientId).Address1
            this.replaceTaxInvoice.TaxCode = this.listClient.find(x => x.Id == this.replaceTaxInvoice.ClientId).TaxCode
            this.replaceTaxInvoice.Email = this.listClient.find(x => x.Id == this.replaceTaxInvoice.ClientId).Email
        },
        handlePaymentType(type) {
            const isShow = type === 'show'
            this.canShowPaymentType = isShow
        },
        handleChoicePayment(payment, trans) {
            this.replaceTaxInvoice.PaymentMethodId = payment.Id
            this.paymentTypeName = `${payment.Name} - ${trans.Description}`
            this.replaceTaxInvoice.TransactionCode = trans.TransactionCode
            this.canShowPaymentType = false
        },
        async detailInvocie_RIVAT02(PaymentId) {
            const body = {
                Invoice: {
                    Id: PaymentId
                }
            }
            const response =  await invoice.api_RIVAT02(body)
            if(response.Status == 200) {
                this.replaceTaxInvoice = response.Data.Invoice
                this.PaymentBookingDetailServiceReq =  [... response.Data.PaymentBookingDetailService] 
                this.getListServiceInoviceVat() 
            }
        },
        async updateInvoice() {
          const body = {
              Invoice: this.replaceTaxInvoice
            }
            if(this.replaceTaxInvoice.BuyerNotGetInvoice) {
              this.replaceTaxInvoice.BuyerNotGetInvoice = true
            } else {
              this.replaceTaxInvoice.BuyerNotGetInvoice = false
            }
            await invoice.api_UIVAT04(body).then(res => {
                this.showResToast(res)
                if(res.Status == 200) {
                    this.$emit('event', {type: 'refresh_list_invoice'})
                    this.clearData()
                    this.hideModal() 
                }
            })
        },
        async call_api_RICF01() {
      // Lấy danh sách config
      const response= await invoice.api_RICF01()
          if (response.Status === '200') {
            this.ListFormNo =this.desired_output(response.Data.InvoiceConfig).map(e=>({
              FormNo:e
            }));
            this.ListSerialNoAll =response.Data.InvoiceConfig
            const checkDefaultInvoice = response.Data.InvoiceConfig.filter(x => x.DefaultInvoiceSymbol === true)
            if (checkDefaultInvoice != null && checkDefaultInvoice.length > 0) {
              this.SerialNoDefault = checkDefaultInvoice[0].SerialNo
              this.FormNoDefault = checkDefaultInvoice[0].FormNo
              this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.FormNoDefault)
            } } 
    },
    desired_output(arr){
      let unique_values = arr
          .map((item) => item.FormNo)
          .filter(
              (value, index, current_value) => current_value.indexOf(value) === index
          );
      return unique_values;
  },
    onChangeConfigEivoice(){
      this.ListSerialNo =this.ListSerialNoAll.filter(x=>x.FormNo==this.replaceTaxInvoice.FormNo)
      this.replaceTaxInvoice.SerialNo = this.ListSerialNo[0].SerialNo
    },
        changeInputOrSelectMember() {
            this.replaceTaxInvoice.MemberName = null
            this.isSelectListMember = !this.isSelectListMember
            if(this.isSelectListMember) {
                if (this.listMember.length == 1) {
                this.replaceTaxInvoice.MemberId = this.listMember[0].id 
                } else {
                this.replaceTaxInvoice.MemberId = null
                }
            } 
        },
        changeCheckVisitorNoInvoice(value) {
          if(value) {
            this.replaceTaxInvoice.ClientId = null
            this.replaceTaxInvoice.CompanyName = null
            this.replaceTaxInvoice.Email = null
            this.replaceTaxInvoice.CompanyAddress = null
            this.replaceTaxInvoice.TaxCode = null
            this.replaceTaxInvoice.MemberName = this.fullNameDefaultVisitorNoInvoice
          }
        },
        clearData() {
            this.replaceTaxInvoice = {
                MemberName: null, 
                ClientId: null,
                CompanyName: null,
                CompanyAddress: null,
                Email: null,
                TaxCode: null,
                PaymentTypeCode: this.listPaymentTypeOpstion[0].value,
                PaymentMethodId: null,
                PaymentMethod: null,
                TransactionCode: null,
                RoomNumber: null,
                CheckIn: null,
                Checkout: null,
                CashierCode: null,
                PaymentBookingDetailService: [],
                FormNo: this.FormNoDefault,
                SerialNo: this.SerialNoDefault,
                RefNo: null
            }
            this.handleChoicePayment(this.listPaymentType[0].PaymentMethod[0], this.listPaymentType[0].PaymentMethod[0].TransactionCode[0])
            const userData = JSON.parse(localStorage.getItem('userData')) || {}
            const currentUser = userData?.userId || {}
            if (this.listCashiers.filter(x => x.id == currentUser).length) {
                this.replaceTaxInvoice.CashierCode = currentUser
            }
        },
        viewAddServiceInvoiceVAT() {
        this.$bvModal.show('modal-add-service-invoice-VAT')
    },

      async getListServiceInoviceVat() {
      this.isLoading = true
      this.PaymentBookingDetailServiceReq = this.PaymentBookingDetailServiceReq.filter((item, index, self) =>
      index === self.findIndex((t) => t.Id === item.Id && t.BookingType === item.BookingType))
      const body = {
        InvoiceId: this.replaceTaxInvoice.Id,
        PaymentBookingDetailService : this.PaymentBookingDetailServiceReq
      }
      const response = await invoice.api_RIVAT04(body)
      //this.showResToast(response)
      if (response.Status === '200') { 
        this.InvoicePaymentBDSV = response.Data
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    DeleteServiceInvoiceVat(value){

      this.PaymentBookingDetailServiceReq = this.PaymentBookingDetailServiceReq.filter(a => 
    !value.InvoicePaymentBookingDetailService.some(b => b.BookingType === a.BookingType && b.Id === a.Id))
    this.getListServiceInoviceVat()
    },
    async ReplaceInvoice_IIVAT04(value){
      console.log(value)
      this.isLoading = true
      const allSv = value.PaymentBookingDetailService.flatMap(item => item.InvoicePaymentBookingDetailService)
       
      const body = {
        InvoiceReplaceId: value.InvoiceId,
        Invoice : {
          MemberName: this.replaceTaxInvoice.MemberName,
          InvoiceDate: this.AdjustInvoiceDate,
          ClientId: this.replaceTaxInvoice.ClientId,
          CompanyName: this.replaceTaxInvoice.CompanyName,
          CompanyAddress: this.replaceTaxInvoice.CompanyAddress,
          Email: this.replaceTaxInvoice.Email,
          TaxCode:  this.replaceTaxInvoice.TaxCode,
          PaymentTypeCode: this.replaceTaxInvoice.PaymentTypeCode,
          PaymentMethodId: this.replaceTaxInvoice.PaymentMethodId,
          PaymentMethod: this.replaceTaxInvoice.PaymentMethod, 
          TransactionCode: this.replaceTaxInvoice.TransactionCode,
          RoomNumber: this.replaceTaxInvoice.PaymentTypeCode,
          CheckIn: this.replaceTaxInvoice.CheckIn,
          Checkout: this.replaceTaxInvoice.Checkout,
          CashierCode: this.replaceTaxInvoice.CashierCode,
          PaymentBookingDetailService: [],
          FormNo: this.replaceTaxInvoice.FormNo,
          SerialNo: this.replaceTaxInvoice.SerialNo,
          RefNo: this.replaceTaxInvoice.RefNo
        },
        InvoicePaymentBookingDetailService : allSv,
      }
      const response = await invoice.api_IIVAT04(body)
      this.showResToast(response)
      if (response.Status === '200') {
       // this.InvoicePaymentBDSV = response.Data
       this.$emit('event', {type: 'refresh_list_invoice'})
        this.clearData()
        this.hideModal()
        this.isLoading = false
      } else {
        this.isLoading = false
      }
    },
    handleEvent(event) {
      console.log(event)
      if (event.type == 'get_payment_service_adjust_einvoice') {
        //this.getListTaxInvoice_RIVAT01A()
        let serviceAdd = event.data.map(item => ({
          Id: item.PaymentBookingDetailServiceId,
          BookingType: item.BookingType 
}));
        this.PaymentBookingDetailServiceReq = [...this.PaymentBookingDetailServiceReq, ...serviceAdd]
        
        this.getListServiceInoviceVat()
      }
    },
    },
}
</script>
<style lang="scss">
#modal-replace-invoice-VAT {
    .paymentType {
            padding: 10px 20px;
            &-header {
                display: flex;
                font-weight: bold;
                &-item {
                    // width: 33%;
                    padding: 5px 0; 
                }
            }
            &-select {
                // display: flex;
                // font-weight: normal;
                &-item {
                    // width: 33%;
                    padding: 5px 0;
                    cursor: pointer;
                }
                &-method:hover {
                    background-color: #e2e9f3;
                    color: #114a9f;
                    cursor: pointer;
                }
            }
        }
    .modal-lg {
    max-width: 75% !important;
        } 
        .dx-datagrid-headers .dx-header-row .dx-datagrid-text-content {
  display: flex; /* Bật chế độ flex */
  justify-content: center; /* Căn giữa ngang */
  align-items: center; /* Căn giữa dọc */
  text-align: center; /* Căn giữa nội dung văn bản */
  text-transform: none;
  font-size: 12px;
  white-space: normal; /* Cho phép xuống dòng */
  word-wrap: break-word; /* Ngắt dòng nếu dài quá */
  overflow-wrap: break-word; /* Ngắt dòng nếu không có khoảng trắng */
}
.text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
}
</style>