import dayjs from 'dayjs'
import { booking } from '@/api/booking'
import { invoice } from '@/api/invoice'
// import { commonServices } from '@/api/common-services'
import ConfirmDialog from '@core/components/confirm-dialog/ConfirmDialog.vue'
import 'devextreme/dist/css/dx.light.css'
import { Workbook } from 'exceljs'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { saveAs } from 'file-saver-es'
import { convertUTC, formatPrice } from '@/@core/utils/utils'
import ExcelJS from "exceljs";
import modalViewEInvoice from './modalViewEInvoice.vue'
import {
  DxDataGrid,
  DxColumn,
  DxEditing,
  DxFilterRow,
  DxHeaderFilter,
  DxGroupPanel,
  DxGrouping,
  DxScrolling,
  DxPaging,
  DxPager,
  DxSummary,
  DxLookup,
  DxTotalItem,
  DxGroupItem,
  DxMasterDetail,
  DxStringLengthRule,
  DxRequiredRule,
  DxRangeRule,
  DxValueFormat,
  DxColumnChooser,
  DxColumnFixing,
  DxSelection,
  DxExport,
} from 'devextreme-vue/data-grid'

const dataGridRef = 'dataGrid'
export default {
  name: 'ListPayment',
  components: {
    DxDataGrid,
    DxColumn,
    DxEditing,
    DxFilterRow,
    DxHeaderFilter,
    DxGroupPanel,
    DxGrouping,
    DxScrolling,
    DxPaging,
    DxPager,
    DxSummary,
    DxLookup,
    DxTotalItem,
    DxGroupItem,
    DxMasterDetail,
    DxStringLengthRule,
    DxRangeRule,
    DxRequiredRule,
    DxValueFormat,
    DxColumnChooser,
    DxColumnFixing,
    DxSelection,
    DxExport,
    ConfirmDialog,
    modalViewEInvoice
  },
  data() {
    return {
      dataGridRef,
      search: {
        InvoiceNo: '',
        KeyWord: '',
        FromDate: this.getUTC('start', 'day', -7),
        ToDate: this.getUTC('start', 'day'),
      },
      listInvoice: [],
      perPage: 10,
      allMode: 'allPages',
      checkBoxesMode: 'onClick', // 'always' :
      optionPerPage: [10, 20, 50],
      configTable: [
        {
          caption: 'SerialNo',
          field: 'SerialNo',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: 'InvoiceNo',
          field: 'InvoiceNo',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_common_full_name'),
          field: 'MemberName',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: 'InvoiceDate',
          field: 'InvoiceDate',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'InvoiceDate',
        },
        {
          caption: this.$t('golf_tax_invoice_company_name'),
          field: 'CompanyName',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_tax_invoice_company_email'),
          field: 'Email',
          isShow: true,
          alignment: 'left',
        },
        {
          caption: this.$t('golf_tax_invoice_company_address'),
          field: 'CompanyAddress',
          isShow: true,
          alignment: 'left',
          // cellTemplate: 'PaymentDate',
        },
        {
          caption: this.$t('golf_tax_invoice_tax_code'),
          field: 'TaxCode',
          isShow: true,
          // cellTemplate: 'TotalAmount',
          alignment: 'right',
        },
        {
          caption: this.$t('golf_tax_invoice_payment_method'),
          field: 'PaymentMethodId',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'PaymentMethodId',
        },
        {
          caption: this.$t('golf_common_total'),
          field: 'TotalAmount',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'TotalAmount',
        },
        {
          caption: this.$t('golf_payment_cashier'),
          field: 'CashierCode',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'CashierCode',
        },
        {
          caption: this.$t('golf_common_status'),
          field: 'Status',
          isShow: true,
          alignment: 'left',
          cellTemplate: 'Status',
        },
        {
          caption: '',
          field: 'action',
          isShow: true,
          cellTemplate: 'action',
          alignment: 'center',
        },
      ],
      isLoading: false,
      listClient: [],
      listCashiers: [],
      zoneId: null,
      listPaymentType: [],
      allMode: 'allPages',
      checkBoxesMode: 'onClick',
      selectedRowsData: [],
      isLoadingExportExcel: false,
      min: null,
      max: null,
      check: null,
      checkModuleEInvocie: JSON.parse(localStorage.getItem('Einvoice')),
      dataViewDetailEInvoice: {
        urlInvocie: null,
        dataInvoice: null
      }
    }
  },
  computed: {
    dataGrid() {
      return this.$refs[dataGridRef].instance
    },
  },
  created() {
    this.getListInvoice_RIVAT01()
    this.getConfigInfo_RCOM01()
    this.getListCashier_RCS01() 
  },
  methods: {
    async deleteInvoice(data) {
      console.log(data)
      await invoice.api_DIVAT02({ Invoice: { Id: data.Id } }).then(async response => {
        if (response.Status === '200') {
          this.showResToast(response)
          this.getListInvoice_RIVAT01()
        }
      })
    },
    getSelectedData() {
      this.selectedRowsData = this.dataGrid.getSelectedRowsData()
    },
    _dayjs: dayjs,
    async getConfigInfo_RCOM01() {
      await booking.getConfigInfo({ KeyGroup: 'BOOKING_CONFIG' }).then(async response => {
        if (response.Status === '200') {
          this.zoneId = response?.Data.filter(item => item.KeyCode === 'BAR_LOCATION_4_MAIN_LOBBY')
          return this.getListPaymentType_RPF02()
        }
      })
    },
    async getListPaymentType_RPF02() {
      await booking.api_RPF02({
        ZoneId: this.zoneId.map(item => String(item.KeyValue)),
      }).then(async response => {
        if (response.Status === '200') {
          const tmp = response?.Data?.PaymentType
          tmp.forEach(x => {
            x.PaymentMethod.forEach(y => {
              if (this.listPaymentType.filter(z => z.Id == y.Id).length == 0) {
                this.listPaymentType.push({
                  Id: y.Id,
                  Name: y.Name,
                })
              }
            })
          })
        }
      })
    },
    async getListCashier_RCS01() {
      await booking.api_RCS01().then(response => {
        if (response.Status === '200') {
          this.listCashiers = response?.Data?.Cashier.map(x => ({
            id: x.Id,
            label: x.CashierName,
          }))
        }
      })
    },
    async getListInvoice_RIVAT01() {
      this.isLoading = true
      await invoice.api_RIVAT01(this.search)
        .then(res => {
          if (res) {
            this.listInvoice = res.Data.Invoice
          }
        })
      this.isLoading = false
    },
    async openPrintInvoice(data) {
      if (data.column.name != 'action' && data.data.Id && data.column.type != 'selection') {
        if(this.checkModuleEInvocie[0]=='2') {
          this.isLoading = true
          if(data.data.EinvoiceStatus == 'PUBLISH') {
            await this.viewEInvocie_RIVAT03(data.data.Id,"DownloadPDF")
          } else {
            await this.viewEInvocie_RIVAT03(data.data.Id,"DownloadPDFInvoiceDraft")
          }
          this.isLoading = false
        } else {
          const routeData = this.$router.resolve({ name: 'InvoiceVAT', params: { id: data.data.Id } })
          window.open(routeData.href, '_blank')
        }
      }
    },
    exportExcel() {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Invoice')
      const dataPaymentMethod = this.listPaymentType
      const dataCashier = this.listCashiers
      exportDataGrid({
        component: this.dataGrid,
        worksheet,
        autoFilterEnabled: true,
        PrintSelectedRowsOnly: true,
        topLeftCell: { row: 4, column: 1 },
        customizeCell(options) {
          const { gridCell, excelCell } = options
          if (gridCell.rowType === 'data') {
            if (gridCell.column.dataField == 'InvoiceDate') {
              excelCell.value = convertUTC(gridCell.value, 'DD/MM/YYYY HH:mm')
            }
            if (gridCell.column.dataField == 'TotalAmount') {
              excelCell.value = formatPrice(gridCell.value)
            }
            if (gridCell.column.dataField == 'PaymentMethodId') {
              excelCell.value = dataPaymentMethod.find(x => x.Id == gridCell.value).Name
            }
            if (gridCell.column.dataField == 'CashierCode') {
              excelCell.value = dataCashier.find(x => x.id == gridCell.value).label
            }
          }
        },
      }).then(cellRange => {
        // header
        const headerRow = worksheet.getRow(2)
        headerRow.height = 30
        worksheet.mergeCells(2, 1, 2, 8)

        headerRow.getCell(1).value = `DANH SÁCH HÓA ĐƠN (từ ${this.convertUTC(this.search.FromDate, 'DD/MM/YYYY')} đến ${this.convertUTC(this.search.ToDate, 'DD/MM/YYYY')})`
        headerRow.getCell(1).font = { name: 'Segoe UI Light', size: 22 }
        headerRow.getCell(1).alignment = { horizontal: 'center' }

        // footer
        const footerRowIndex = cellRange.to.row + 2
        const footerRow = worksheet.getRow(footerRowIndex)
        worksheet.mergeCells(footerRowIndex, 1, footerRowIndex, 8)
        footerRow.getCell(1).font = { color: { argb: 'BFBFBF' }, italic: true }
        footerRow.getCell(1).alignment = { horizontal: 'right' }
      }).then(() => {
        workbook.xlsx.writeBuffer().then(buffer => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Invoice.xlsx')
        })
      })
    },
    async exportExcelInvoice() {
      this.getSelectedData()
      if(this.selectedRowsData.length > 0) {
        this.isLoadingExportExcel = true
        let dataExportExcel = []
        var RowNumber = 0
        var checkError = false
        this.min = 0
        this.max = this.selectedRowsData.length
        this.check = 0
        for(let i = 0; i <this.selectedRowsData.length;i++) {
          const body = {
            Invoice : {
                Id: this.selectedRowsData[i].Id
            }
        }
        await invoice.api_RIVAT02(body).then(res => {
            if(res.Status == '200') {
              res.Data.Invoice.Service.forEach((x,index) => {
                RowNumber += 1
                x.STT = RowNumber
                x.SerialNo =  res.Data.Invoice.SerialNo + res.Data.Invoice.InvoiceNo
                x.InvoiceNo = res.Data.Invoice.InvoiceNo
                x.InvoiceDate =  this.convertUTC(res.Data.Invoice.InvoiceDate,"DD/MM/YYYY")
                x.RoomNumber = res.Data.Invoice.RoomNumber
                x.MemberName = res.Data.Invoice.MemberName
                x.CompanyName = res.Data.Invoice.CompanyName
                x.TaxCode = res.Data.Invoice.TaxCode
                x.Email = res.Data.Invoice.Email
                x.CompanyAddress = res.Data.Invoice.CompanyAddress
                x.PaymentMethodName = res.Data.Invoice.TransactionCodeName ? res.Data.Invoice.TransactionCodeName : res.Data.Invoice.PaymentMethodName
                x.CheckIn = res.Data.Invoice.CheckIn ? this.convertUTC(res.Data.Invoice.CheckIn,"DD/MM/YYYY") : null
                x.Checkout = res.Data.Invoice.Checkout ? this.convertUTC(res.Data.Invoice.Checkout,"DD/MM/YYYY") : null
                x.ServiceCharge= x.ServiceCharge ?  Math.round(x.ServiceCharge): 0
                x.SpecialTaxAmount = x.SpecialTaxAmount ? Math.round(x.SpecialTaxAmount) : 0
                x.VatValue = x.VatValue * 100 + "%"
                x.UnitPrice = Math.round(x.UnitPrice)
                x.Amount = Math.round(x.Amount)
                x.TaxAmount = Math.round(x.TaxAmount)
                dataExportExcel.push(x)
              })
            } else {
              checkError = true
            }
        })
          if(checkError) {
            this.isLoadingExportExcel = false
            this.showToast('error', this.$t('golf_common_error_number_invoice') + this.selectedRowsData[i].InvoiceNo)
            break
          }
          this.check += 1
            if(this.check === this.max) {
              this.isLoadingExportExcel = false
              this.showToast('success', this.$t('golf_common_success'))
          }
        }
        if(checkError) {
          return
        }
        this.generateXLSX(dataExportExcel)
      } else {
        this.showToast('error', this.$t('golf_common_please_select_invoice'))
      }
    },
    generateXLSX(data) {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet");
      worksheet.columns = [
          { header: "STT", key: "STT"},
          { header: "BillSeri1", key: "SerialNo"},
          { header: "Vat bill", key: "InvoiceNo"},
          { header: "InvDate", key: "InvoiceDate"},
          { header: "TrnDescription", key: "ServiceName"},
          { header: "Unit", key: "UnitName"},
          { header: "so luong", key: "Quantity"},
          { header: "Don gia", key: "UnitPrice"},
          { header: "thanh tien", key: "Amount"},
          { header: "SV5%", key: "ServiceCharge"},
          { header: "THDB", key: "SpecialTaxAmount"},
          { header: "VAT", key: "VatValue"},
          { header: "Tax10%Amou", key: "TaxAmount"},
          { header: "TotalAmo", key: "TotalAmount"},
          { header: "Room", key: "RoomNumber"},
          { header: "KHACHHANG", key: "MemberName"},
          { header: "COMPANY", key: "CompanyName"},
          { header: "VATCode", key: "TaxCode"},
          { header: "Address1", key: "CompanyAddress"},
          { header: "ModeOfPayment", key: "PaymentMethodName"},
          { header: "Arrdate", key: "CheckIn"},
          { header: "DepDate", key: "Checkout"},
          { header: "email", key: "Email"},
      ];
      data.forEach( x => {
          worksheet.addRow(x)
      })

      workbook.xlsx.writeBuffer().then(buffer => {
      saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `ChiTietHoaDon.xlsx`)
      })
    },
    async createEinvocieDraft_IIVAT02(InvocieGolfId) {
      const body = {
        InvoiceGolfId: InvocieGolfId
      }
      const response =  await invoice.api_IIVAT02(body)
      return response
    },
    async issueEinvocie_IIVAT03(InvocieGolfId) {
      const body = {
        InvoiceGolfId: InvocieGolfId
      }
      const response =  await invoice.api_IIVAT03(body)
      return response
    },
    async viewEInvocie_RIVAT03(InvocieGolfId, ViewType) {
      const body = {
        InvoiceGolfId: InvocieGolfId,
        Type: ViewType
      }
      const response =  await invoice.api_RIVAT03(body)
      if(response.Status == 200) {
        if(response.Data.Type === 1){
          const decodedData = Buffer.from(response.Data.InvoicePDF, 'base64')
          const newBlob = new Blob([decodedData], {
            type: 'application/pdf',
          })
          const url = window.URL.createObjectURL(newBlob)
          this.dataViewDetailEInvoice.urlInvocie = url
          this.dataViewDetailEInvoice.dataInvoice = InvocieGolfId
          this.$bvModal.show('modal-view-invoice-VAT')
          this.$refs.viewEInvocie.open()
        } else {
          window.open(response.Data.InvoicePDF, '_blank', 'noreferrer');

        }
       
      }
    },
    async createEinvocieDraft() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        const response_IIVAT02 = await this.createEinvocieDraft_IIVAT02(this.selectedRowsData[0].Id)
        this.showResToast(response_IIVAT02)
        if(response_IIVAT02.Status == 200) {
          if(this.selectedRowsData[0].EinvoiceStatus == 'PUBLISH') {
            await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDF")
          } else {
            await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDFInvoiceDraft")
          }
          this.isLoading = false
        }
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    async viewEInvocie() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        if(this.selectedRowsData[0].EinvoiceStatus == 'PUBLISH') {
          await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDF")
        } else {
          await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDFInvoiceDraft")
        }
        this.isLoading = false
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    handleEvent(event) {
      if(event.type == 'refresh_list_invoice') {
        this.getListInvoice_RIVAT01()
      }
    },
    async issueEinvoice() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        if(this.selectedRowsData[0].EinvoiceStatus != "PUBLISH") {
          const response = await this.issueEinvocie_IIVAT03(this.selectedRowsData[0].Id)
          this.showResToast(response)
          if(response.Status == 200) {
            await this.viewEInvocie_RIVAT03(this.selectedRowsData[0].Id,"DownloadPDF")
          }
          this.isLoading = false
        } else {
          this.showToast('error', this.$t('golf_invoice_EInvoice_issued'))
          this.isLoading = false
        }
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    async deleteEinvoice() {
      this.isLoading = true
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        const body = {
          InvoiceGolfId: this.selectedRowsData[0].Id
      }
        const response =  await invoice.api_DIVAT03(body)
        this.showResToast(response)
        if(response.Status == 200) {
          this.getListInvoice_RIVAT01()
        }
        this.isLoading = false
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
    },
    async confimDeleteEivoice(){
      this.getSelectedData()
      if(this.selectedRowsData.length == 1) {
        this.$refs.confirmDialogComponent1.open({
          dataConfirm: {
            title: 'Huỷ hóa đơn',
            content: 'Bạn có chắc chắn Huỷ hóa đơn?',
          }
        })
      } else {
        this.showToast('error', this.$t('golf_invoice_please_select_one_invoice'))
        this.isLoading = false
      }
      
    }
    
  },
}
